import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import newsmaxSvc from './newsmax-svc';
// Commented if we want to use config.theme
// import './msd-styles.css';

import reportWebVitals from './reportWebVitals';
import docket_no_svc from './docket-no-svc';
import fluent_svc from './fluent-svc';

let docket = docket_no_svc.docket_no;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  window.document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

var searchParams = new URLSearchParams(window.location.search)
var debugMode = searchParams.get('debugmode');

if(debugMode) {
  $("#root").after(`
    <style>
    #debugger {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: #f8d7da;
      border: 1px solid #dc3545;
      z-index: 2147483648;
    }
    .log-data-container {
      border-top: 1px solid #dc3545;
      max-height: 250px;
      overflow-y: scroll;
    }
    .log-data-container code {
      display: block;
      font-size: 11px;
      border: 1px solid #d6338440;
      padding: 0.25rem;
      margin-bottom: 0.5rem;
    }
    </style>
    <div id="debugger">
      <div class="container-fluid">
        <div class="d-inline-flex gap-1">
          <b class="mr-1">Debugger</b>&nbsp;
          <a data-bs-toggle="collapse" href="#multiCollapseInitialRequest" role="button" aria-expanded="false" aria-controls="multiCollapseInitialRequest">Initial Request</a>&nbsp;
          <a data-bs-toggle="collapse" href="#multiCollapseLog" role="button" aria-expanded="false" aria-controls="multiCollapseLog">Event Log</a>
        </div>
        <div class="row log-data-container">
          <div class="col">
            <div class="collapse" id="multiCollapseInitialRequest">
              <div class="card-header">Initial Request</div>
              <div class="card card-body mb-3">
                <ul>
                  <li><b>Docket</b>: ${docket}</li>
                  <li><b>Location</b>: ${window.location}</li>
                  <li><b>Referrer</b>: ${window.document.referrer}</li>
                  <li><b>User-Agent</b>: ${window.navigator.userAgent}</li>
                </ul>
              </div>
            </div>
            <div class="collapse" id="multiCollapseLog">
              <div class="card-header">Event Log</div>
              <div class="card card-body mb-3">
                <ul></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `);

  Object.keys(window.localStorage).map(function(e, i, a) {
    $('#multiCollapseInitialRequest ul').append(`<li><b>LocalStorage[${e}]</b>: ${window.localStorage[e]}</li>`);
  });
}

// capture the landing location; referrer; ...
const location = window.location.href || null;
const referrer = window.document.referrer || null;

if(sessionStorage.is_nm_session_started === undefined || sessionStorage.is_nm_session_started === null) {
  sessionStorage.is_nm_session_started = true;
  const session_start = {
    docketNo: docket,
    location,
    fluentClickId: fluent_svc.click_id,
    userAgent: window.navigator.userAgent,
    referrer
  };
  newsmaxSvc
    .save_state('NM_session-start', session_start)
    .subscribe({ error: ex => console.error({ 'NM_session-start': ex }) });
}

[
  'MSSDK:Purchase-loaded',
  'MSSDK:purchase-successful',
  'MSSDK:purchase-failed',
  'MSSDK:Adyen-error',
  'MSSDK:redeem-coupon-success',
  'MSSDK:redeem-coupon-failed',
  'MSSDK:redeem-coupon-button-clicked',
  'MSSDK:redeem-button-clicked',
  'MSSDK:unsubscribe-button-clicked',
  'MSSDK:unsubscribe-action-confirmed',
  'MSSDK:unsubscribe-action-cancelled',
  'MSSDK:resume-button-clicked',
  'MSSDK:resume-action-confirmed',
  'MSSDK:resume-action-cancelled',
  'MSSDK:switch-button-clicked',
  'MSSDK:switch-popup-action-cancelled',
  'MSSDK:switch-popup-action-successful',
  'MSSDK:switch-popup-action-failed',
  'MSSDK:cancel-switch-button-clicked',
  'MSSDK:cancel-switch-action-cancelled',
  'MSSDK:cancel-switch-action-triggered',
  'MSSDK:cancel-switch-action-successful',
  'MSSDK:cancel-switch-action-failed',
  'MSSDK:edit-payment-button-clicked',
  'MSSDK:update-payment-details-successful',
  'MSSDK:update-payment-details-failed',
  'MSSDK:remove-payment-details-button-clicked',
  'MSSDK:remove-payment-details-action-confirmed',
  'MSSDK:remove-payment-details-action-cancelled',
  'MSSDK:auth-failed',
  'MSSDK:good-news-page',
  'MSSDK:payment-page'
].forEach(topic => window.addEventListener(topic, evt => {
  let data = evt.detail || {};
  try {
    newsmaxSvc.save_state(topic.replace(':', '_'), {
      ...data,
      docketNo: docket,
      fluentClickId: fluent_svc.click_id,
      location,
      userAgent: window.navigator.userAgent,
      referrer
    }).subscribe({ error: ex => console.error({ [topic]: ex }) });
  } catch(error) { console.error(`Could not send '${topic}' event: `, error); }

  if(debugMode) {
    var dataSent = {
      ...data,
      docketNo: docket,
      fluentClickId: fluent_svc.click_id,
      location,
      userAgent: window.navigator.userAgent,
      referrer
    };

    $('#multiCollapseLog ul').prepend(`<li>Event <b>${topic}</b>: <code>${JSON.stringify(dataSent, null, '  ')}</code></li>`);
  }
}));

$(window).on("scroll", function() {
  var scrollHeight = $(window.document).height();
  var scrollPosition = $(window).height() + $(window).scrollTop();
  if ((scrollHeight / 2.7) <= scrollPosition) {
    $('#footerPopup').addClass('show-popup');
  } else {
    $('#footerPopup').removeClass('show-popup');
  }
});
function closePopup() {
  $('#footerPopup').removeClass('show-popup');
}