import { Observable, map } from 'rxjs';

export class svc_base {
  fetch(url, settings) {
    return new Observable(obs => {
      const ctlr = new AbortController();
      fetch(url, {
        ...settings,
        signal: ctlr.signal,
        redirect: 'manual'
      }).then(
        rs => {
          if(rs.ok) {
            obs.next(rs);
          }
          else {
            obs.error(rs);
          }
        },
        ex => obs.error(ex)
      );
      return () => ctlr.abort('cancelled');
    });
  }

  fetch_blob(url, settings) {
    return new Observable(obs => {
      const ctlr = new AbortController();
      fetch(url, {
        ...settings,
        signal: ctlr.signal,
        redirect: 'manual'
      }).then(
        rs => {
          if(rs.ok) {
            rs.blob().then(
              rt => {
                obs.next(rt);
                obs.complete();
              },
              ex => obs.error(ex)
            );
          }
          else {
            obs.error(rs);
          }
        },
        ex => obs.error(ex)
      );
      return () => ctlr.abort('cancelled');
    });
  }

  fetch_json(url, settings) {
    return new Observable(obs => {
      const ctlr = new AbortController();
      fetch(url, {
        ...settings,
        signal: ctlr.signal,
        redirect: 'manual'
      }).then(
        rs => {
          if(rs.ok) {
            if((rs.headers.get('Content-Length') || 0) == 0) {
              obs.complete();
            }
            else {
              rs.json().then(
                rt => {
                  obs.next(rt);
                  obs.complete();
                },
                ex => obs.error(ex)
              );
            }
          }
          else {
            obs.error(rs);
          }
        },
        ex => obs.error(ex)
      );
      return () => ctlr.abort('cancelled');
    });
  }
}