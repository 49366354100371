import Auth from "../../Auth"
import { NavLink } from 'react-router-dom';
import logo from './images/NewsmaxPlus_Logo.png';

import {
  HeaderStyled,
  LogoStyled,
  StyledPlus
} from './HeaderAccStyled';

const HeaderAcc = ({ isHomePage }) => {
  return (
    <HeaderStyled isHomePage={isHomePage}>
      <nav class="navbar fixed-top bg-body-tertiary">
        <div class="container-fluid">
          <NavLink to="/"><LogoStyled src={logo} /></NavLink>
          <Auth />
        </div>
      </nav>
    </HeaderStyled>
  )
}

export default HeaderAcc;