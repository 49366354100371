import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './App.css';
import { Auth as MediastoreAuth } from '@cleeng/mediastore-sdk';
import { usePopupsContext, popupTypes } from './components/Popups/Popups';
import config from './Config';

const Dropdown = ({ title, items }) => {
  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {title}
      </a>
      <ul className="dropdown-menu">
        {items.map((item, index) => (
          <li key={index}>
            <a href={item.to} className="dropdown-item" rel="noopener noreferrer">
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
};

const Auth = () => {
  const { showModal } = usePopupsContext();
  const location = useLocation();

  const dropdownItems = [
    {
      to: `${config.sso.url}/#/sso/email-change?client_id=${config.sso.clientId}&email=${window.localStorage?.CLEENG_CUSTOMER_EMAIL}`, 
      label: 'Change Email',
    },
  ];

  return (
    <div className="AppAuth">
      <header className="App-header">
        <ul className="menu mb-0 p-0">
          {!MediastoreAuth.isLogged() && (
            <span className="navbar-text text-center text-sm-end h6">
              Already have an account?<br />
              <a href="#" class="btn btn-primary text-light" id="topLogin" onClick={() => showModal(popupTypes.LOGIN)}>
                LOGIN
              </a>
            </span>
          )}
          {MediastoreAuth.isLogged() && (
            <>
              {window.location.pathname === '/account' ? (
                <Dropdown title="My Account" items={dropdownItems} />
              ) : (
                <li>
                  <NavLink id="myAccount" to="/account">My Account</NavLink>
                </li>
              )}
              <li>
                <button
                  type="button" id="logoutBtn"
                  onClick={() => {
                    MediastoreAuth.logout();
                    window.location.href = '/';
                  }}
                >
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      </header>
    </div>
  );
};

export default Auth;
