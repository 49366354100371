// NOTE: adding configuration settings here must be replicated in the Config.js files in the (dev) and (stage) folders.

// to configure for sandbox local builds, uncomment (line 5) and comment or remove the 'const config...' statement (starting at lines 7)
// IMPORTANT!!! this is for building a sandbox version locally. DO NOT push this change to devops.
//import config from './dev/Config';

const config = {
  default: {
    docket_no: '101256v52gr8'
  },
  sso: {
    clientId: '65268e9ceba38de90727daf2',
    url: 'https://sso.newsmax.com'
  },
  cleeng: {
    api_url: 'https://sso.newsmax.com/cleeng',
    environment: 'production',
    payment_methods: ['card', 'applepay', 'googlepay'],
    publisher_id: '560288637'
  },
  domain: 'www.newsmaxplus.com',
  fluent_click_id_cache_expire_in_hours: 24,
  offer: {
    annually: 'S701282737_US',
    monthly: 'S198697297_US',
    test: 'S355235058_US',
    redirect_to_nmtv: true
  }
};

export default config;