import Auth from "../../Auth"
import { NavLink } from 'react-router-dom';
import logo from './images/NewsmaxPlus_Logo.png';

import {
  HeaderStyled,
  LogoStyled,
  StyledPlus
} from './HeaderStyled';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Header = ({ isHomePage }) => {
  return (
    <HeaderStyled isHomePage={isHomePage}>
      <div class="container-fluid">
        <nav class="navbar row bg-body-tertiary">
          <div class="container-fluid" >
            
            <div>
            <NavLink to="/"><LogoStyled src={logo} /></NavLink>
            </div>
            <div class={useLocation().pathname == "/winback" ? "hide" : "show"}>
            <Auth />
            </div>
          </div>
        </nav>
      </div>
    </HeaderStyled>
  )
}

export default Header;