const prefix = '$cache_';

function get_storage_key(key) {
  return `${prefix}${key}`;
}

export class cached_storage {
  constructor(repos) {
    this.repos = repos;
  }

  clear() {
    return this.repos.clear();
  }

  getItem(key) {
    const skey = get_storage_key(key);
    const rt = this.repos.getItem(skey);
    if(rt) {
      const rs = JSON.parse(Buffer.from(rt, 'base64'));
      const now = Date.now();
      if(rs.option.absolute_expire && now >= rs.option.absolute_expire) {
        this.repos.removeItem(skey);
        return null;
      }
      if(rs.option.expire && now >= rs.option.expire) {
        this.repos.removeItem(skey);
        return null;
      }
      if(rs.option.sliding_expire) {
        this._set_cached_item(key, rs.value, rs.option);
      }
      return rs.value;
    }
    return this.repos.getItem(key);
  }

  key(idx) {
    const rt = this.repos.key(idx);
    return rt?.startsWith(prefix) ? rt.splice(prefix.length) : rt;
  }

  /**
   * set a cached item
   * @param {*} key (string, required) unique identifier of cached item
   * @param {*} value (string, required) value of the cached item
   * @param {*} option (object, optional) caching options {absolute_expire: number, sliding_expire: number *in seconds}
   */
  setItem(key, value, option) {
    if(option && (option.sliding_expire || option.absolute_expire)) {
      this._set_cached_item(key, value, option);
    }
    else {
      this.repos.setItem(key, value);
    }
  }

  removeItem(key) {
    return this.repos.removeItem(key);
  }

  _set_cached_item(key, value, option) {
    if(option.sliding_expire) {
      let dt = new Date();
      dt.setSeconds(dt.getSeconds() + option.sliding_expire);
      option.expire = dt.valueOf();
    }
    value = Buffer.from(JSON.stringify({
      value,
      option
    })).toString('base64');
    this.repos.setItem(get_storage_key(key), value);
  }

  get length() {
    return this.repos.length;
  }
}