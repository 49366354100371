import { EMPTY } from 'rxjs';
import { svc_base } from './svc-base';
import { cached_storage } from './cached-storage';
import cfg from './Config';

const fluent_click_id = 'fluent_click_id';

export class fluent_svc extends svc_base {
  constructor() {
    super();
    this.storage = new cached_storage(window.localStorage);
    if(window.location.pathname.startsWith('/fluent')) {
      var searchParams = new URLSearchParams(window.location.search)
      var click_id = searchParams.get('Click_ID');
      if(click_id) {
        let dt = new Date();
        dt.setHours(dt.getHours() + cfg.fluent_click_id_cache_expire_in_hours);
        this.storage.setItem(fluent_click_id, click_id, { absolute_expire: dt.valueOf() });
      }
    }
  }

  set_pixel() {
    const click_id = this.click_id;
    return click_id ? this.fetch_blob(`https://f.cstpersl.com/aff_lsr?offer_id=17086&transaction_id=${click_id}`, {
      method: 'GET'
    }) : EMPTY;
  }

  get click_id() {
    return this.storage.getItem(fluent_click_id);
  }
}

export default new fluent_svc();