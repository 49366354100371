
import { useState, createContext, useContext } from 'react';
import { Login, Register, Checkout, PasswordReset, Capture } from '@cleeng/mediastore-sdk';
import fluent_svc from '../../fluent-svc';
import { firstValueFrom } from 'rxjs';

import {
  PopupsWrapperStyled
} from './PopupsStyled';
import cfg from '../../Config';
import $ from 'jquery';
import nm_svc from '../../newsmax-svc';
import docket from '../../docket-no-svc';

export const popupTypes = {
  CHECKOUT_MONTHLY: 'checkout_monthly',
  CHECKOUT_ANNUALLY: 'checkout_annually',
  CHECKOUT_TEST: 'checkout_test',
  LOGIN: 'login',
  REGISTER: 'register',
  PASSWORD_RESET: 'passwordReset',
  CAPTURE: 'capture'
}

const initialState = {
  showModal: () => { },
  hideModal: () => { },
  openCCForm: () => { }
}

const PopupContext = createContext(initialState);
export const usePopupsContext = () => useContext(PopupContext);


export const Popups = ({ children }) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [popupType, setPopupType] = useState('checkout');

  const showModal = (popupType) => {
    setPopupType(popupType);
    setIsPopupOpened(true);

    console.log(popupType);
    // Open CC form
    if(popupType == "checkout_monthly"
      || popupType == "checkout_annually"
      || popupType == "checkout_test"
    ) {
      openCCForm();
    }
  }

  const hideModal = (route) => {
    setIsPopupOpened(false);

    // If purchased, redirect if param present
    if(route == "purchase") {
      var searchParams = new URLSearchParams(window.location.search)

      try {
        window.gtag("event", "purchase", {
          docket: docket.docket_no,
          cleeng_offer_id: window.localStorage.CLEENG_OFFER_ID,
          cleeng_order_id: window.localStorage.CLEENG_ORDER_ID,
          cleeng_customer_id: window.localStorage.CLEENG_CUSTOMER_ID
        });
      }
      catch(error) {
        console.log("Could not send purchase event to GA4: ", error);
      }

      if(searchParams.has('redirect')) {
        setTimeout(function() {
          window.location.href = searchParams.get('redirect');
        }, 8000);
      }
      else if(cfg.offer.redirect_to_nmtv) {
        setTimeout(function() {
          window.location.href = "https://newsmaxtv.com";
        }, 8000);
      }
    }
    else if(route == "account") {
      window.location.href = "/account";
    }
  }

  const openCCForm = () => {
  }

  return (
    <PopupContext.Provider value={{ showModal, hideModal, openCCForm }}>
      {children}
      {isPopupOpened && (
        <Popup popupType={popupType} setPopupType={setPopupType} />
      )}
    </PopupContext.Provider>
  )
}

export const Popup = ({ popupType, setPopupType }) => {
  const { hideModal } = usePopupsContext();

  async function do_fluent_postback() {
    const rs = await firstValueFrom(fluent_svc.set_pixel(), { defaultValue: { text: async () => '' } });
    if(rs) {
      const data = {
        fluent_response: await rs.text(),
        fluentClickId: fluent_svc.click_id,
        docketNo: docket.docket_no
      };
      if(data.fluent_response) {
        await firstValueFrom(nm_svc.save_state('nmFluentResponse', data), { defaultValue: true });
      }
      return rs;
    }
  }

  async function post_checkout() {
    try {
      await Promise.all([
        do_fluent_postback(),
        firstValueFrom(nm_svc.set_user_external_data(), { defaultValue: true })
      ]);
    }
    catch(ex) {
      const data = {
        post_checkout_failed: ex,
        fluentClickId: fluent_svc.click_id,
        docketNo: docket.docket_no
      };
      console.error(data);
      await firstValueFrom(nm_svc.save_state('nmPostCheckoutFailed', data), { defaultValue: true });
    }
    hideModal("purchase");
  }

  return (
    <PopupsWrapperStyled>
      <div className="popupWrapper" onClick={() => {
        hideModal()
      }}>
        <div className="popupContent" onClick={(event) => {
          event.stopPropagation();
        }}>
          {popupType === popupTypes.LOGIN && (
            <Login
              onSuccess={() => {
                hideModal("account");
                nm_svc.set_adyen_cfg().catch(ex => console.error({ failed_to_get_adyen_settings: ex }));
              }}
              onRegisterClick={() => setPopupType(popupTypes.REGISTER)}
              onPasswordResetClick={() => setPopupType(popupTypes.PASSWORD_RESET)} />
          )}
          {popupType === popupTypes.REGISTER && (
            <Register
              onSuccess={() => setPopupType(popupTypes.CAPTURE)}
              onHaveAccountClick={() => setPopupType(popupTypes.LOGIN)} />

          )}
          {popupType === popupTypes.CAPTURE && (
            <Capture
              onSuccess={() => hideModal()}
            />
          )}
          {popupType === popupTypes.CHECKOUT_MONTHLY && (
            <Checkout onSuccess={post_checkout} offerId={cfg.offer.monthly} />
          )}
          {popupType === popupTypes.CHECKOUT_ANNUALLY && (
            <Checkout onSuccess={post_checkout} offerId={cfg.offer.annually} />
          )}
          {popupType === popupTypes.CHECKOUT_TEST && (
            <Checkout onSuccess={post_checkout} offerId={cfg.offer.test} />
          )}
          {popupType === popupTypes.PASSWORD_RESET && (
            <PasswordReset onSuccess={() => hideModal()} />
          )}
        </div>
      </div>
    </PopupsWrapperStyled>
  )
}