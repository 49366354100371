import cfg from './Config';
import { firstValueFrom } from 'rxjs';
import { svc_base } from './svc-base';
import docket_svc from './docket-no-svc';

export class newsmax_svc extends svc_base {
  createPaymentSession() {
    return this.fetch_json(`${newsmax_svc.get_mediastore_sdk_url()}/connectors/adyen/sessions`, {
      body: JSON.stringify({
        //orderId: window.localStorage.CLEENG_ORDER_ID,
        returnUrl: 'https://www.newsmax.com'
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.CLEENG_AUTH_TOKEN}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });
  }

  static get_mediastore_sdk_url() {
    return window.localStorage.CLEENG_ENVIRONMENT === 'sandbox' ? 'https://mediastoreapi-sandbox.cleeng.com' : 'https://mediastoreapi.cleeng.com/';
  }

  async get_adyen_cfg() {
    const value = window.localStorage.cleeng_adyen_cfg;
    let rt = value ? JSON.parse(value) : {
      paymentMethodsConfiguration: {
        card: {
          brands: [
            "visa",
            "mc",
            "discover",
            "amex"
          ]
        },
        applepay: {
          amount: {
            currency: "USD",
            value: 0
          },
          countryCode: "US",
          configuration: {
            merchantId: "000000000224856",
            merchantName: "Adyen"
          }
        },
        googlepay: {
          amount: {
            currency: "USD",
            value: 0
          },
          countryCode: "US",
          environment: "TEST",
          configuration: {
            merchantId: "50",
            gatewayMerchantId: "CleengSandboxUS_NYC",
            merchantName: "Adyen"
          }
        }
      }
    };
  }

  async set_adyen_cfg() {
    let paymentSession = await firstValueFrom(this.createPaymentSession());
    if(paymentSession.errors?.length === 0) {
      const adyenConfiguration = {
        paymentMethodsConfiguration: {
          card: {
            brands: ['visa', 'mc', 'discover', 'amex']
          },
          applepay: {
            amount: paymentSession.responseData.amount,
            countryCode: paymentSession.responseData.countryCode,
            configuration: paymentSession.responseData.paymentMethods.find(x => x.type === 'applepay')?.configuration
          },
          googlepay: {
            amount: paymentSession.responseData.amount,
            countryCode: paymentSession.responseData.countryCode,
            environment: "TEST",
            configuration: paymentSession.responseData.paymentMethods.find(x => x.type === 'googlepay')?.configuration
          }
        }
      };
      adyenConfiguration.paymentMethodsConfiguration.applepay.configuration.merchantName = adyenConfiguration.paymentMethodsConfiguration.googlepay.configuration.merchantName = 'Adyen';
      window.localStorage.cleeng_adyen_cfg = JSON.stringify(adyenConfiguration);
    }
  }

  save_state(topic, data) {
    return this.fetch(`${cfg.cleeng.api_url}/webhooks`, {
      body: JSON.stringify({
        broadcasterId: 560288637,
        topic,
        data: { ...data, customerId: window.localStorage.CLEENG_CUSTOMER_ID ? Number.parseInt(window.localStorage.CLEENG_CUSTOMER_ID) : null }
      }),
      headers: {
        Accepts: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });
  }

  set_user_external_data() {
    return this.save_state('nmOrderDetails', {
      docketNo: docket_svc.docket_no,
      orderId: window.localStorage.CLEENG_ORDER_ID,
      customerEmail: window.localStorage.CLEENG_CUSTOMER_EMAIL
    });
  }
}

export default new newsmax_svc();