import { Auth, Login, Register, PasswordReset, CheckoutConsents, ThankYouPage, RedeemGift } from '@cleeng/mediastore-sdk';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RedeemMyGiftStyled } from './RedeemMyGiftStyled';
import HeaderAcc from '../../components/HeaderAcc/HeaderAcc';
import { useRouteMatch } from 'react-router-dom';

const steps = {
  login: {
    stepNumber: 0,
    nextStep: 2
  },
  register: {
    stepNumber: 1,
    nextStep: 2
  },
  consents: {
    stepNumber: 2,
    nextStep: 3
  },
  redeem: {
    stepNumber: 3,
    nextStep: 4
  },
  pwd_reset: {
    stepNumber: 4,
    nextStep: 0
  }
};

class RedeemMyGift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: steps.login.stepNumber
    };
  }

  componentDidMount() {
    if(Auth.isLogged()) {
      this.setState({
        currentStep: steps.consents.stepNumber
      });
    }
  }

  goToStep = step => {
    this.setState({
      currentStep: step
    });
  };


  render() {
    const { currentStep } = this.state;
    const { onSuccess, offerId } = this.props;

    switch(currentStep) {
      case 0:
        return (
          <RedeemMyGiftStyled>
            <HeaderAcc />
            <Login
              onSuccess={() => this.goToStep(steps.login.nextStep)}
              onRegisterClick={() => this.goToStep(steps.register.stepNumber)}
              onPasswordResetClick={() => this.goToStep(steps.register.pwd_reseet.stepNumber)}
            />
          </RedeemMyGiftStyled>
        );
      case 1:
        return (
          <RedeemMyGiftStyled>
            <HeaderAcc />
            <Register
              onSuccess={() => this.goToStep(steps.register.nextStep)}
              onHaveAccountClick={() => this.goToStep(steps.login.stepNumber)}
            />
          </RedeemMyGiftStyled>
        );
      case 2:
        return (
          <RedeemMyGiftStyled>
            <HeaderAcc />
            <CheckoutConsents
              onSuccess={() => this.goToStep(steps.consents.nextStep)}
            />
          </RedeemMyGiftStyled>
        );
      case 3:
        return (
          <RedeemMyGiftStyled>
            <HeaderAcc />
            <RedeemGift />
          </RedeemMyGiftStyled>
        );
      case 4:
        return <ThankYouPage onSuccess={() => onSuccess()} />;
      case 5:
        return <PasswordReset />;
      default:
        return null;
    }
  }
}

RedeemMyGift.propTypes = {
  offerId: PropTypes.string,
  onSuccess: PropTypes.func
};

RedeemMyGift.defaultProps = {
  offerId: null,
  onSuccess: () => { }
};

export default RedeemMyGift;