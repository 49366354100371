import {
  HomePageWrapperStyled,
  BuyButtonStyled
} from './Home2Styled';
import Header from '../../components/Header/Header';
import { usePopupsContext, popupTypes } from '../../components/Popups/Popups';

const HomePage = () => {
  const { showModal } = usePopupsContext();

  return (
   <HomePageWrapperStyled>
      <Header isHomePage />

      <div id="watch" class="mkt exp2 experience container-fluid"> {/* Start of #watch container */}

        <div id="fluentHero" class="row py-md-5 blue-grad">
          <div class="col-10 offset-1 col-xl-8 col-xxl-6 p-4 my-5">
            <div class="text-center text-md-start text-light">
              <h2 class="mb-4">Don't just watch the news - experience it.</h2>
              <h3>Start watching Newsmax+ today.</h3>
              <h4>Plans Start at $4.99/mo. Cancel anytime.</h4>
              <h4>Start Your Free 15-day Trial Below.</h4>
            </div>
          </div>
        </div>

        <div class="row py-5" id="choosePlan">
          <div class="col">
            <div class="row pb-md-5">
              <div class="col-md-10 offset-md-1 col-xl-8 offset-xl-2 col-xxl-6 offset-xxl-3">
                <h2 class="text-center text-light mb-5">Choose Your Plan</h2>

                <div class="row pb-md-5 mt-3">
                    <div class="col-10 offset-1">
                      <div class="row gx-5 mt-3">
                        <div class="col-md-6 col-xl-12 col-xxl-6">
                          <div class="signUpBox card text-center signUpYearly border-0" style={{boxShadow: '2px 3px 18px 3px #ffffff6b'}}>
                            <div class="card-header accent-blue fs-3">
                              Yearly<br />
                              $49.99 / year
                            </div>
                              <h4 class="card-header promo text-danger mt-4 text-end me-5 pe-4">SAVE&nbsp;$10!</h4>
                            <div class="card-body">
                              <ul class="text-start fs-5">
                                <li>Exclusive Content</li>
                                <li>Breaking News and Expert Analysis</li>
                                <li>Affordable Pricing</li>
                                <li>Access From Anywhere, Any Device</li>
                                <li>15-Day Trial</li>
                                <li><span class="bg-accent-yellow fw-bold">Lower Annual Price</span></li>
                              </ul>
                              <div class="text-center pt-4">
                                <BuyButtonStyled id="TannualSub" className='btn btn-danger btn-lg px-5' onClick={() => showModal('checkout_annually')}>Start Your Free Trial</BuyButtonStyled>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-xl-12 col-xxl-6 mb-3 mb-md-2">
                          <div class="signUpBox card text-center signUpMonthly border-0"> 
                            <div class="card-header accent-blue fs-4">
                              Monthly<br />
                              $4.99 / month
                            </div>
                            <h5 class="card-header promo text-danger mt-3">&nbsp;</h5>
                            <div class="card-body">
                              <ul class="text-start fs-5">
                                <li>Exclusive Content</li>
                                <li>Breaking News and Expert Analysis</li>
                                <li>Affordable Pricing</li>
                                <li>Access From Anywhere, Any Device</li>
                                <li>15-Day Trial</li>
                                <li class="invisible">&nbsp;</li>
                              </ul>
                              <div class="text-center pt-4">
                                <BuyButtonStyled id="TmonthlySub" className='btn btn-danger btn-lg px-5' onClick={() => showModal('checkout_monthly')}>Start Your Free Trial</BuyButtonStyled>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          
            <div class="row">
              <div class="col">
                <h5 class="mt-3 text-light text-center">Already have an account? <a href="#" id="WBmidLogin" class="link-light" onClick={() => showModal(popupTypes.LOGIN)}>Login Here</a></h5>
              </div>
            </div>
          </div>
        </div>

        <div class="row" id="whyNMPlus01">
          <div class="py-md-5 pb-5 blue-grad">
            <div class="col-md-5 offset-md-1 p-4 pb-5 pb-md-4 my-5 text-center text-md-start text-light">
              <h2 class="mb-3">Exclusive Content</h2>
              <h3 class="pb-5 mb-5 pb-md-0 mb-md-0 fw-normal">Gain unlimited access to our unique news content and documentaries, including exclusive interviews and content not available anywhere else.</h3>
            </div>
          </div>
        </div>

        <div class="row" id="whyNMPlus02">
          <div class="bnews-title-mobile col-12 d-block d-md-none blue-grad">
            <div class="my-3">
              <div class="p-4 text-center text-light">
                <h2 class="mb-3">Breaking News and Expert Analysis</h2>
                <h3 class="fw-normal">Stay informed with breaking news delivered in real-time and get expert analysis from renowned contributors and political pundits.</h3>
              </div>
            </div>
          </div>
          <div class="col bnews1">
            <div class="bnews-title my-5 d-md-block d-none">
              <div class="col-md-6 offset-md-1 col-lg-5 py-5 text-start text-light">
                <h2 class="mb-3">Breaking News and Expert Analysis</h2>
                <h3 class="fw-normal">Stay informed with breaking news delivered in real-time and get expert analysis from renowned contributors and political pundits.</h3>
              </div>
            </div>
          </div>
          <img src="images/bnews02.jpg" class="col d-block d-xl-none p-0 img-fluid" />
        </div>

        <div class="row" id="whyNMPlus02-shows">
          
        </div>

        <div class="row py-5" id="whyNMPlus03">
          <div class="col-10 offset-1 px-4 py-5 my-5 text-light text-center">
            <h2 class="mb-3">No Commitments and Affordable Pricing</h2>
            <h3 class="fw-normal">Enjoy the freedom of no long-term commitments. Our subscription is flexible, allowing you to cancel anytime if you choose to do so. We offer competitive pricing options that fit every budget. Just $4.99 per month or get an entire year for only $49.99 (Best Value - Save 16%)</h3>
          </div>
        </div>

        <div class="row pb-5 pt-3 pt-md-5 dark-blue-gradient text-light" id="whyNMPlus04">
          <div class="col-12 col-md-5 offset-md-1 p-4 ps-5 my-md-5 pt-3 pt-md-4">
            <h2 class="mb-3 mt-5">Access From Anywhere, Any Device</h2>
            <h3 class="fw-normal">Whether you're at home or on-the-go, you can access our vast library from any device, be it your computer, smartphone, or tablet.</h3>
            <br />
            <h3 class="fw-normal"><strong>Newsmax+</strong> is available on all of your favorite mobile devices, smart TVs, and OTT devices.</h3>
            <div class="row">
              <div class="col col-lg-12 offset-lg-0 col-xxl-8 offset-xxl-2">
                <div class="row row-cols-2 row-cols-sm-3 g-3 mt-3 text-center text-sm-start">
                  <div class="col">
                    <a href="https://www.samsung.com/us/tvs/smart-tv/samsung-tv-plus-and-smart-hub/" target="_blank" rel="noreferrer">
                      <img class="img-fluid" src="images/app-samsung.png" alt="Samsung"></img>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://www.vizio.com/en/smart-tv-apps?appName=newsmax&appId=vizio.newsmax" target="_blank" rel="noreferrer">
                      <img class="img-fluid" src="images/app-vizio.png" alt="Vizio"></img>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://channelstore.roku.com/details/24699/newsmax-tv" target="_blank" rel="noreferrer"> 
                      <img class="img-fluid" src="images/app-roku.png" alt="Roku"></img>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://play.google.com/store/apps/details?id=com.fli.android.newsmaxapp" target="_blank" rel="noreferrer">
                      <img class="img-fluid" src="images/app-sony.png" alt="Sony"></img>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://us.lgappstv.com/main/tvapp/detail?appId=988054&catCode1=006&moreYn=N&cateYn=Y&orderType=0&headerName=News%2FInfo&appRankCode=&sellrUsrNo=&curPage=2" target="_blank" rel="noreferrer">
                      <img class="img-fluid" src="images/app-lg.png" alt="LG"></img>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://www.newsmaxtv.com" target="_blank" rel="noreferrer">
                      <img class="img-fluid" src="images/app-nmtv.png" alt="Newsmax TV"></img>
                    </a>
                  </div>
                  <div class="col">
                    <a href="https://itunes.apple.com/us/app/newsmax/id878180850?mt=8" target="_blank" rel="noreferrer">
                      <img class="img-fluid" src="images/app-apple.png" alt="Apple"></img>
                      </a>
                    </div>
                    <div class="col">
                      <a href="https://play.google.com/store/apps/details?id=com.fli.android.newsmaxapp&utm_source=browser&utm_campaign=npluspage&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel="noreferrer">
                        <img class="img-fluid" src="images/app-google.png" alt="Android"></img>
                      </a>
                    </div>
                    <div class="col">
                      <a href="https://www.amazon.com/Newsmax-Media-Inc-TV-Web/dp/B00QVZP4WW/" target="_blank" rel="noreferrer">
                        <img class="img-fluid" src="images/app-amazon.png" alt="Amazon"></img>
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex flex-wrap align-items-center justify-content-center">
          <img src="./images/devices.png" class="img-fluid" alt="Newsmax Devices" />
          </div>
        </div>

        <div class="row py-5 dark-blue-gradient-rev text-light" id="faqNMPlus">
          <div class="col-10 offset-1 col-lg-6 offset-lg-3 my-4">
            <h2>Frequently Asked Questions</h2>
            <div class="mt-3 mb-5">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      Why does Newsmax require a subscription?
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <p>Under our new cable agreements, Newsmax is not allowed to be streamed free.</p>
                        <p>In its place you will find N2 or Newsmax2, our new channel for top headlines and informative shows.</p>
                        <p>But if you want Newsmax with great shows like Rob Schmitt, Greta Van Susteren, Greg Kelly and more &ndash; you will need to sign up for Newsmax+.</p>
                        <p>Newsmax+ works just like Netflix. Get Newsmax streamed directly to the Newsmax App on your home TV store or smartphone.</p>
                        <p>Sign up now at NewsmaxPlus.com to get your credentials for easy access on the App!</p>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      How can I watch Newsmax?
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                      <div class="accordion-body">
                        <p>There are several convenient ways to watch Newsmax:</p>
                        <ol>
                          <li><strong>Newsmax Plus Subscription:</strong> Watch Newsmax by subscribing to our subscription service Newsmax+.<br />
                          With a Newsmax Plus subscription, you'll have access to Newsmax content on various platforms, including smartphone devices, Connected TV apps, and popular Over-The-Top (OTT) platforms, such as Apple TV, Roku, Amazon Firestick, Google Chromecast, and others. You can also watch online through your web browser at NewsmaxTV.com. <br />
                          Start watching wherever you are. Simply download the Newsmax app on your home TV, smartphone or preferred OTT device.<br />
                          Then create an account at NewsmaxPlus.com, log in on your OTT device with your Newsmax Plus subscription credentials, and start streaming our channel!</li>
                          <li><strong>Cable Providers:</strong> Newsmax is available on most cable and satellite pay TV systems.<br />
                          So if you have a cable TV subscription, you can continue to access Newsmax through your cable provider. <br />
                          Please check your channel lineup to find Newsmax and enjoy our content as part of your cable package.</li>
                        </ol>
                        <p>By offering these options, we aim to make it convenient for you to stay informed and connected with Newsmax. </p>
                        <p>Whether you prefer mobile access, streaming via OTT apps, or watching through your cable provider, we have you covered.</p>
                        <p>Thank you for choosing Newsmax as your trusted source for news and information. </p>
                        <p>If you have any questions or need further assistance, please don't hesitate to reach out to our customer support team.</p>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      How do I create a Newsmax Plus subscription to watch Newsmax?
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div class="accordion-body">
                        <p>To get started today: To create a Newsmax Plus subscription, open your web browser and go to our website, which is NewsmaxPlus.com.</p>
                        <ol>
                          <li><strong>Visit the Newsmax Plus Website:</strong> Open your web browser and go to our website, NewsmaxPlus.com.</li>
                          <li><strong>Choose a Subscription Option:</strong> On the NewsmaxPlus.com website, scroll down and choose a subscription option: $4.99 a month or $49.99 for a year (Save $10).</li>
                          <li><strong>Create an Account:</strong> After selecting your subscription, you’ll be prompted to create an account. Provide your email address and create a password. Click the “Register” button to finish creating your account. </li>
                          <li><strong>Complete the Checkout Process:</strong> After creating an account, you'll be directed to the checkout page. Here, you'll need to enter your payment information to finalize your subscription. Follow the prompts to complete the transaction securely.</li>
                          <li><strong>Download the App:</strong> Now that you're a Newsmax Plus subscriber, you can download the Newsmax app on your preferred device, such as your phone or home TV. Whether it's a mobile phone (iOS/Android), tablet, Roku, Amazon Firestick, Apple TV, streaming services on Samsung, Vizio, LG, others &ndash; or any other compatible device &ndash; you can find the Newsmax App in your device's app store.</li>
                          <li><strong>Log in with Your Newsmax Plus Subscription Credentials:</strong> Open the Newsmax app on your device, or on the NewsmaxTV.com website, and log in using the credentials you created during the subscription process. This will give you access to Newsmax Plus content, including our channel.</li>
                        </ol>
                        <p>If you encounter any issues or have questions during this process, please don't hesitate to contact our customer support team for assistance. We're here to help you make the most of your Newsmax Plus subscription.</p>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                      Can I cancel my subscription?
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                      <div class="accordion-body">
                        <p>Yes, you may cancel your Newsmax Plus subscription at any time. This can be done by logging into your account at NewsmaxPlus.com and navigating to “My Account.” Choose “Cancel” under plan details. For further assistance, please don't hesitate to reach out to our customer support team.</p>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                      What content is available with my Newsmax Plus subscription?
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                      <div class="accordion-body">
                        <p>With Newsmax Plus, you can enjoy an array of valuable content, which includes:</p>
                        <ul>
                          <li>Newsmax channel 24/7</li>
                          <li>Access to the archive for great shows like “Rob Schmitt Tonight,” and “Greg Kelly Reports” – and all of our top shows!</li>
                          <li>Documentaries on President Trump, Joe Biden’s presidency, Faith in America, the Rise of Woke, and much more.</li>
                          <li>Historical programs you can’t find elsewhere about the founding of America, the Civil War, presidential greats like Abe Lincoln, George Washington, and Ronald Reagan, World War II . . . among many others.</li>
                          <li>Comedy documentaries that will have you laughing – with Bob Hope, Don Rickles, and more!</li>
                          <li>Special briefings from top newsmakers in the worlds of politics, health, and finance</li>
                          <li>And much more!</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                      How can I reach Newsmax customer support regarding Newsmax Plus?
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                      <div class="accordion-body">
                        <p>You can contact customer by phone or email.</p>
                        <ul>
                          <li>Our customer support number is 1-800-485-4350. Office hours are Mon-Fri 8:30am - 8:30pm ET, and Sat 9:00am - 5:00pm ET.</li>
                          <li>Our customer support email is <a href="mailto:customerservice@newsmax.com">customerservice@newsmax.com</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <h3><a href="https://support.newsmaxplus.com/" target="_blank" class="text-white">For more FAQs visit our Help Center</a></h3>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row text-bg-dark py-4" id="footerNMPlus">
          <div class="col col-lg-8 offset-lg-2 text-center mt-4">
            <p class="fs-6"><small><a href="https://www.newsmax.com/termsconditions/?utm_source=nmplus&utm_medium=footer" target="_blank" rel="noreferrer">Terms</a>&nbsp; | &nbsp;<a href="https://www.newsmax.com/privacystatement/?utm_source=nmplus&utm_medium=footer" target="_blank" rel="noreferrer">Privacy</a>&nbsp; | &nbsp;<a href="https://www.newsmax.com/about/?utm_source=nmplus&utm_medium=footer" target="_blank" rel="noreferrer">About</a>&nbsp; | &nbsp;<a href="https://www.newsmax.com/contact/?utm_source=nmplus&utm_medium=footer" target="_blank" rel="noreferrer">Contact</a><br />
            <a href="https://www.newsmax.com/?utm_source=nmplus&utm_medium=footer" target="_blank" rel="noreferrer">Newsmax.com</a>&nbsp; | &nbsp;<a href="https://www.newsmaxtv.com/?utm_source=nmplus&utm_medium=footer" target="_blank" rel="noreferrer">NewsmaxTV.com</a>&nbsp; | &nbsp;<a href="https://www.newsmaxplus.com/?utm_source=nmplus&utm_medium=footer" target="_blank" rel="noreferrer">NewsmaxPlus.com</a></small></p>
          </div>
        </div>
        
        <div class="footer-popup" id="footerPopup">
          <div class="container text-light text-center text-md-start">
            <div class="row py-3">
              <div class="col-md-9"> 
                <h3><strong>Subscribe to Newsmax+ today! Start your free trial.</strong></h3>
              </div>
              <div class="col-md-3">
                <a class="btn btn-danger btn-lg" href="#choosePlan">Start Your Free Trial</a>
                </div>
            </div>
           
          </div>
        </div>

      </div> {/* End of #watch container */}
    </HomePageWrapperStyled>
  )
}

export default HomePage;