import cfg from './Config';
import fluent_svc from './fluent-svc';

export class docket_no_svc {
  constructor() {
    if(!this.docket_no) {
      // Fetch dkt_nbr from query param and save in session storage
      let searchParams = new URLSearchParams(window.location.search)
      let docket = searchParams.get('dkt_nbr');
      if(!docket) {
        if(fluent_svc.click_id) {
          docket = '0318277o8zim';
        }
        else {
          switch(window.location.pathname) {
            case "/subscribe": docket = '0706293dxoh0'; break;
            case "/fluent": docket = '0318277o8zim'; break;
            case "/experience": docket = '071929qocyeg'; break;
            case "/subscribeexperience": docket = '103489zb9a2s'; break;
            case "/trump": docket = '103489di19d2'; break;
            default: docket = cfg.default.docket_no; break;
          }
        }
      }
      window.sessionStorage.dkt_nbr = docket;
    }
  }

  get docket_no() {
    return window.sessionStorage.dkt_nbr;
  }
}

export default new docket_no_svc();